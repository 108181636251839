import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Navbar from './components/navbar'

import Protected from './controllers/Protected'

import Login from './screens/auth/Login'
import Home from './screens/home'
import Upload from './screens/upload'
import Edit from './screens/edit'

import './App.css'

export class App extends Component {
  render() {
    return (
      <div className='App'>
        <BrowserRouter>
          <Navbar></Navbar>
          <Routes>
            <Route path='/login' element={<Login />}></Route>
            
            <Route path='/' element={Protected(Home)}></Route>
            <Route path='/upload' element={Protected(Upload)}></Route>
            <Route path='/edit/:slug' element={Protected(Edit)}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}

export default App