import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";

import { withRouter } from "../controllers/withRouter";

import Spinner from "../components/spinner";

let categories = [
  { value: "opinion", label: "Opinion" },
  { value: "sports", label: "Sports" },
  { value: "business", label: "Business" },
  { value: "entertainment", label: "Entertainment" },
  { value: "life-living", label: "Life & Living" },
  { value: "youth", label: "Youth" },
  { value: "tech-startup", label: "Tech & Startup" },
  { value: "movies-series", label: "Movies & Series" },
  { value: "medical-news", label: "Medical News" },
  { value: "tragedy-news", label: "Tragedy News" },
  { value: "gaming-news", label: "Gaming News" },
  { value: "multimedia", label: "Multimedia" },
  { value: "finance", label: "Finance" },
  { value: "feature-news", label: "Feature News" },
  { value: "international-news", label: "International News" },
  { value: "bangladeshi-news", label: "Bangladeshi News" },
  { value: "breaking-news", label: "Breaking News" },
  { value: "weird-news", label: "Weird News" },
  { value: "travel-living", label: "Travel & Living" },
  { value: "earth", label: "Earth" },
  { value: "law-order", label: "Law & Order" },
  { value: "environment", label: "Environment" },
];

export class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: "",
      title: "",
      content: "",
      category: "",
      language: "",
      thumbnail: "",
      source: "",
      sourceLink: "",
    };

    this.getDefaultCategory = this.getDefaultCategory.bind(this);
  }

  async componentDidMount() {
    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/get-blog/${this.props.params.slug}`,
        config
      );

      if (data.success) this.setState({ loading: false, data: data.blog });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  getDefaultCategory() {
    let defaultValue = [];

    for (let i = 0; i < categories.length; i++) {
      for (let j = 0; j < this.state.data.category.length; j++) {
        if (categories[i].value === this.state.data.category[j])
          defaultValue.push(categories[i]);
      }
    }

    return defaultValue;
  }

  async submit(event) {
    event.preventDefault();

    this.setState({ loading: true });

    let formData = new FormData();

    formData.append("id", this.state.data._id);
    if (this.state.title !== "") formData.append("title", this.state.title);
    if (this.state.content !== "")
      formData.append("content", this.state.content);
    if (this.state.category !== "")
      formData.append("category", JSON.stringify(this.state.category));
    if (this.state.source !== "") formData.append("source", this.state.source);
    if (this.state.sourceLink !== "")
      formData.append("sourceLink", this.state.sourceLink);
    if (this.state.previewThumbnail !== "")
      formData.append("thumbnail", this.state.thumbnail);
    if (this.state.language !== "")
      formData.append("language", this.state.language);

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/edit`,
        formData,
        config
      );

      if (data.success) {
        alert("Article have been edited");
        window.location.href = `/`;
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  render() {
    return (
      <div className="my-5 py-5">
        <div className="container">
          <h1 className="text-center mb-3">Edit Blog</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <form>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  defaultValue={this.state.data.title}
                  onChange={(event) =>
                    this.setState({ title: event.target.value })
                  }
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Language
                </label>
                <select
                  defaultValue={this.state.data.language}
                  className="form-control"
                  onChange={(event) =>
                    this.setState({ language: event.target.value })
                  }
                >
                  <option value="english">English</option>
                  <option value="bangla">Bangla</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Content</label>
                <textarea
                  cols="30"
                  rows="10"
                  className="form-control"
                  defaultValue={this.state.data.content}
                  onChange={(event) =>
                    this.setState({ content: event.target.value })
                  }
                  required
                ></textarea>
              </div>

              <div className="mb-3">
                <label className="form-label">Category</label>
                <Select
                  options={categories}
                  isMulti
                  defaultValue={this.getDefaultCategory()}
                  onChange={(value) => this.setState({ category: value })}
                ></Select>
              </div>

              <div className="mb-3">
                {this.state.thumbnail ? (
                  <img
                    src={URL.createObjectURL(this.state.thumbnail)}
                    alt={this.state.title}
                    className="img-fluid"
                  ></img>
                ) : (
                  <img
                    src={this.state.data.thumbnail}
                    alt={this.state.data.title}
                    className="img-fluid"
                  />
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Thumbnail</label>
                <input
                  type="file"
                  onChange={(event) =>
                    this.setState({ thumbnail: event.target.files[0] })
                  }
                  className="form-control form-control-lg"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Source</label>
                <input
                  type="text"
                  defaultValue={this.state.data.source}
                  onChange={(event) =>
                    this.setState({ source: event.target.value })
                  }
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Source Link</label>
                <input
                  type="text"
                  defaultValue={this.state.data.sourceLink}
                  onChange={(event) =>
                    this.setState({ sourceLink: event.target.value })
                  }
                  required
                  className="form-control"
                />
              </div>

              <div className="my-4 d-grid">
                <button type="submit" onClick={(event) => this.submit(event)}>
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Edit);
